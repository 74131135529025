<template>
<div class="wrapper" style="padding-bottom:150px;" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header"> 
        <div class="flex items-center gap-3">
            <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.go(-1)">
                <i class="el-icon-arrow-left"></i>
            </button>
            <h1>{{ $route.params.id ? res1.name_cyr : $t('add_new_woter_base') }}</h1>
        </div>
    </div>
    <div class="wp-body"> 
        <div class="uform"> 
                    <el-form :model="res1" :rules="rules1" ref="res1" class="Form">
                        <div class="input-section">
                            <el-row :gutter="30">
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{$t('water_base_name')}} </p>
                                        <el-form-item prop="name_cyr">
                                            <el-input :disabled="disabled1" v-model="res1.name_cyr" :placeholder="$t('water_base_name')" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('water_base_region')}} </p>
                                        <el-form-item prop="region">
                                            <el-select :disabled="disabled1" v-model="res1.region" :loading="loading" class="w-full" :placeholder="$t('water_base_region')">
                                                <el-option v-for="item in dictionary.region" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p>{{$t('water_base_district')}} </p>
                                        <el-form-item prop="district">
                                            <el-select :disabled="disabled1" v-model="res1.district" class="w-full" clearable :placeholder="$t('water_base_district')">
                                                <el-option v-for="item in current_district" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('water_base_address')}} </p>
                                        <el-form-item prop="address">
                                            <el-input :disabled="disabled1" v-model="res1.address" :placeholder="$t('water_base_address')" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('water_base_year_of_construction') }} </p>
                                        <el-form-item prop="year_of_construction">
                                            <el-date-picker :disabled="disabled1" style="width: 100%" v-model="res1.year_of_construction" type="year" value-format="yyyy" :placeholder="$t('water_base_year_of_construction')">
                                            </el-date-picker>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p>{{$t('water_base_date_of_accept')}}</p>
                                        <el-form-item prop="date_of_accept">
                                            <el-date-picker :disabled="disabled1" style="width: 100%" v-model="res1.date_of_accept" value-format="dd.MM.yyyy" format="dd.MM.yyyy" :placeholder="$t('water_base_date_of_accept')">
                                            </el-date-picker>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p>{{$t('water_base_reservoir_function')}} </p>
                                        <el-form-item prop="reservoir_function">
                                            <el-select :disabled="disabled1" v-model="res1.reservoir_function" class="w-full" clearable :placeholder="$t('water_base_reservoir_function')">
                                                <el-option v-for="item in dictionary.reservoirfunction" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p>{{$t('water_base_main_ingredient')}} </p>
                                        <el-form-item prop="main_ingredient">
                                            <el-select :disabled="disabled1" v-model="res1.main_ingredient" class="w-full" clearable :placeholder="$t('water_base_main_ingredient')">
                                                <el-option v-for="item in dictionary.main_ingredient" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p>{{$t('water_base_water_source')}}</p>
                                        <el-form-item prop="water_source">
                                            <el-select :disabled="disabled1" v-model="res1.water_source" class="w-full" clearable :placeholder="$t('water_base_water_source')">
                                                <el-option v-for="item in dictionary.watersource" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>

                                </el-col>
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{$t('water_base_reservoir_class')}} </p>
                                        <el-form-item prop="reservoir_class">
                                            <el-select :disabled="disabled1" v-model="res1.reservoir_class" class="w-full" clearable :placeholder="$t('water_base_reservoir_class')">
                                                <el-option v-for="item in dictionary.reservoirclass" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('water_base_reservoir_type')}} </p>
                                        <el-form-item prop="reservoir_type">
                                            <el-select :disabled="disabled1" v-model="res1.reservoir_type" class="w-full" clearable :placeholder="$t('water_base_reservoir_type')">
                                                <el-option v-for="item in dictionary.reservoirtype" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('water_base_organization_name')}} </p>
                                        <el-form-item prop="organization_name">
                                            <el-input :disabled="disabled1" v-model="res1.organization_name" :placeholder="$t('water_base_organization_name')" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('water_base_user_organization_name')}} </p>
                                        <el-form-item prop="user_organization_name">
                                            <el-input :disabled="disabled1" v-model="res1.user_organization_name" :placeholder="$t('water_base_user_organization_name')" clearable />
                                        </el-form-item>
                                    </label>

                                    <label class="item">
                                        <p>{{$t('water_base_earthquake_tolerance')}} </p>
                                        <el-form-item prop="earthquake_tolerance">
                                            <el-input :disabled="disabled1" v-model="res1.earthquake_tolerance" :placeholder="$t('water_base_earthquake_tolerance')" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('water_base_dam_type')}} </p>
                                        <el-form-item prop="dam_type">
                                            <el-select :disabled="disabled1" v-model="res1.dam_type" class="w-full" clearable :placeholder="$t('water_base_dam_type')">
                                                <el-option v-for="item in dictionary.dam_type" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    
                                    <label class="item">
                                        <p> {{$t('finalconclusion')}} </p>
                                        <el-form-item prop="finalconclusion">
                                            <el-select :disabled="disabled1" class="w-full" v-model="res1.finalconclusion" clearable  :placeholder="$t('finalconclusion')" >
                                                <el-option v-for="item in dictionary.finalconclusion" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                     <label class="item">
                                        <p> {{$t('water_base_buildingseismicity')}} </p>
                                        <el-form-item prop="buildingseismicity">
                                            <el-select :disabled="disabled1" class="w-full" v-model="res1.buildingseismicity" clearable  :placeholder="$t('water_base_buildingseismicity')" >
                                                <el-option v-for="item in dictionary.buildingseismicity" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>

                                </el-col>
                                <el-col :md="8">
                                    <!-- Сув омборининг лойиҳа бўйича асосий кўрсаткичлари-->
                                    <p class="indicator ">{{$t('water_base_first_done')}}:</p>
                                    <label class="item">
                                        <p> {{$t('water_base_max_water_volume')}} </p>
                                        <div class="flex">
                                            <el-form-item class="flex-auto w-full" prop="max_water_volume.value">
                                                <el-input type="number" :disabled="disabled1" :placeholder="$t('water_base_max_water_volume')" v-model="res1.max_water_volume.value" clearable />
                                            </el-form-item>
                                            <el-form-item prop="max_water_volume.unit">
                                                <el-select :disabled="disabled1" v-model="res1.max_water_volume.unit" class="ml-3" clearable>
                                                    <el-option v-for="item in units.unit_4" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('water_base_normal_water_volume')}} </p>
                                        <div class="flex">
                                            <el-form-item class="flex-auto w-full" prop="normal_water_volume.value">
                                                <el-input type="number" :disabled="disabled1" v-model="res1.normal_water_volume.value" :placeholder="$t('water_base_normal_water_volume')" clearable />
                                            </el-form-item>
                                            <el-form-item prop="normal_water_volume.unit">
                                                <el-select :disabled="disabled1" v-model="res1.normal_water_volume.unit" class="ml-3" clearable>
                                                    <el-option v-for="item in units.unit_4" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('water_base_dead_water_volume')}} </p>
                                        <div class="flex">
                                            <el-form-item class="flex-auto w-full" prop="dead_water_volume.value">
                                                <el-input type="number" :disabled="disabled1" v-model="res1.dead_water_volume.value" :placeholder="$t('water_base_dead_water_volume')" clearable />
                                            </el-form-item>
                                            <el-form-item prop="dead_water_volume.unit">
                                                <el-select :disabled="disabled1" v-model="res1.dead_water_volume.unit" class="ml-3" clearable>
                                                    <el-option v-for="item in units.unit_4" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </label>
                                    <!-- ---------------------- -->
                                    <p class="indicator "> {{$t('woter_base_damp')}}: </p>
                                    <label class="item">
                                        <p>{{$t('max_height')}} </p>
                                        <div class="flex">
                                            <el-form-item class="flex-auto w-full" prop="max_height.value">
                                                <el-input type="number" :disabled="disabled1" :placeholder="$t('max_height')" v-model="res1.max_height.value" clearable />
                                            </el-form-item>
                                            <el-form-item prop="max_height.unit">
                                                <el-select :disabled="disabled1" v-model="res1.max_height.unit" class="ml-3" clearable>
                                                    <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('peak_length')}} </p>
                                        <div class="flex">
                                            <el-form-item class="flex-auto w-full" prop="peak_length.value">
                                                <el-input type="number" :disabled="disabled1" :placeholder="$t('peak_length')" v-model="res1.peak_length.value" clearable />
                                            </el-form-item>
                                            <el-form-item prop="peak_length.unit">
                                                <el-select :disabled="disabled1" v-model="res1.peak_length.unit" class="ml-3" clearable>
                                                    <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </label>
                                    <!-- <p class="indicator mt-3">Сув омборининг 2021 ойидаги ҳолатига</p>
                            <label class="item">
                                <p>Mаксимал сув ҳажми</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="floors_height.value">
                                        <el-input type="number" :disabled="disabled1" placeholder="Mаксимал сув ҳажми" v-model="res1.date_of_accept" clearable />
                                    </el-form-item>
                                    <el-form-item prop="floors_height.unit">
                                        <el-select :disabled="disabled1" v-model="res1.max_water_volume.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_4" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p>Hормал сув  ҳажми</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="floors_height.value">
                                        <el-input type="number" :disabled="disabled1" placeholder="Hормал сув  ҳажми" v-model="res1.date_of_accept" clearable />
                                    </el-form-item>
                                    <el-form-item prop="floors_height.unit">
                                        <el-select :disabled="disabled1" v-model="res1.max_water_volume.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_4" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p>Ўлик сув ҳажми</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="floors_height.value">
                                        <el-input type="number" placeholder="Ўлик сув ҳажми" :disabled="disabled1" v-model="res1.date_of_accept" clearable />
                                    </el-form-item>
                                    <el-form-item prop="floors_height.unit">
                                        <el-select :disabled="disabled1" v-model="res1.max_water_volume.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_4" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label> -->

                                    <div class="actions mt-10">
                                        <div class=" flex justify-end w-full" v-if="role !== 'admin' &&  !disabled1">
                                            <!-- <el-button v-if="res1.id" class="primary-btn"><i class="el-icon-edit mr-1"></i>Ўзгартириш</el-button> -->
                                            <div>
                                                <el-button @click.native="save1()" class="primary-btn " style="width:150px"><i class="el-icon-document-checked mr-1"></i> {{$t('save')}} </el-button>
                                            </div>

                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>  
        </div>  
         
        
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            disabled1: false,
            disabled2: false,
            isCreated2: false,
            loading: false,
            activeNames: "1",
            current_district: {},
            obj: {},
            isCreated: false,
            res1: {
                name_cyr: '',
                address: '',
                year_of_construction: '',
                date_of_accept: '',
                organization_name: '',
                user_organization_name: '',
                earthquake_tolerance: '',
                region: '',
                district: "",
                water_source: '',
                reservoir_function: '',
                reservoir_class: '',
                reservoir_type: '',
                dam_type: '',
                main_ingredient: '',
                buildingseismicity:'',
                max_water_volume: {
                    value: null,
                    unit: 9
                },
                normal_water_volume: {
                    value: null,
                    unit: 9
                },
                dead_water_volume: {
                    value: null,
                    unit: 9
                },
                max_height: {
                    value: null,
                    unit: 1
                },
                peak_length: {
                    value: null,
                    unit: 1
                },
                finalconclusion: ''
            },
            rules1: {
                name_cyr: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                address: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                year_of_construction: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                date_of_accept: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                organization_name: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                user_organization_name: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                earthquake_tolerance: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                region: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                district: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                water_source: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                reservoir_function: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                reservoir_class: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                reservoir_type: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                dam_type: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                buildingseismicity:  {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                main_ingredient: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                max_water_volume: {
                    value: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],
                    unit: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],

                },
                normal_water_volume: {
                    value: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],
                    unit: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],
                },
                dead_water_volume: {
                    value: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],
                    unit: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],
                },
                max_height: {
                    value: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],
                    unit: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],
                },
                peak_length: {
                    value: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],
                    unit: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }],
                },
                finalconclusion: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
            },
        }
    },
    computed: {
        dictionary() {
            return this.$store.state.dictionary;
        },
        units() {
            return this.$store.state.units;
        },
        role() {
             return this.$store.state.role;
        },
        page() {
            return this.$route.query.id || '';
        },

    },
    watch: {
        'res1.region': function () {
            this.current_district = [];

            this.loading = true;
            axios.get(`/dictionary/district/?region=${this.res1.region}`)
                .then((response) => {
                    this.current_district = response.data;
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    mounted() { 
        if (this.$route.params.id) {
            this.disabled1 = this.$route.params.edit == '1' ? true : false
            if (this.role === 'admin') {
                this.disabled1 = true;
            }
            this.__GET()
        }

    },
    methods: {
        __GET() {
            if (this.$route.params.id) {
                this.loading = true;
                axios.get(`/reservoir/waterreservoir/${this.$route.params.id}`)
                    .then((response) => {
                        response.data.year_of_construction = String(response.data.year_of_construction)
                        this.res1 = response.data;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            } else {
                this.loading = false;
                this.$store.commit('reservoirIsCreated', false)
            }
        },
        save1() {
            this.$refs.res1.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if (!this.$route.params.id) {
                        axios.post('/reservoir/waterreservoir/', this.res1)
                            .then((response) => {
                                axios.post(`/reservoir/waterreservoir/${response.data.id}/generate/`)
                                    .then(res => { 
                                        this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link

                                    })
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'Сув омбори яратилди',
                                    timer: 3000
                                })
                                this.$router.push({ path: '/reservoir'})
                            })
                            .catch(error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_create'),
                                    timer: 3000
                                })
                            })
                            .finally(() => {
                                this.loading = false;
                            })
                    } else {
                        axios.put(`/reservoir/waterreservoir/${this.$route.params.id}/`, this.res1)
                            .then((response) => {
                                axios.post(`/reservoir/waterreservoir/${response.data.id}/generate/`)
                                    .then(res => { 
                                        this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link

                                    })
                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('data_saved'),
                                    timer: 3000
                                })
                                this.$router.push({
                                    path: '/reservoir'
                                })
                            })
                            .catch(error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_save'),
                                    timer: 3000
                                })
                            })
                            .finally(() => {
                                this.loading = false;
                            })
                    }
                }

            })
        },
        regionChanged() {
            this.current_district = [];
            this.spec1.district = [];
            this.getDistrict();
        },
        getDistrict() {
            this.loading = true;
            axios
                .get(`/dictionary/district/?region=${this.res1.region}`)
                .then((response) => {
                    this.current_district = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.indicator {
    font-weight: 500;
    font-size: 20px;
    padding: 15px 0;
    border-bottom: 2px solid #004787;
}

.el-collapse-item__header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #004787 !important;
    padding: 15px 0;
    border-bottom: none;
}

.build-tab {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;

    li {
        cursor: pointer;
        width: 400px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #d4d4d4;
        font-size: 17px;
        line-height: 24px;
        color: #004787;
        position: relative;
        user-select: none;

        &.active {
            border-bottom: solid 3px #004787;
        }
    }
}  

</style>
